import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [30,[2]],
		"/(no-auth)/access-denied": [146,[29]],
		"/(app)/billing": [31,[2,3]],
		"/(app)/billing/credits": [32,[2,3]],
		"/(app)/billing/invoices": [33,[2,3]],
		"/(app)/billing/plan": [34,[2,3]],
		"/(app)/billing/plan/delete-account": [35,[2]],
		"/(app)/billing/plan/delete-account/[slug]": [36],
		"/(app)/data-converter/[port]": [37,[2]],
		"/(no-auth)/debugger": [147,[29]],
		"/(app)/e2eUtils/clean-namespaces": [38,[2,4]],
		"/(app)/e2eUtils/clean-users": [39,[2,4]],
		"/(app)/get-started/profile/experience": [40,[5,7],[6]],
		"/(app)/get-started/profile/goal": [41,[5,7],[6]],
		"/(app)/get-started/profile/invite-teammates": [42,[5,7],[6]],
		"/(app)/get-started/profile/platform": [43,[5,7],[6]],
		"/(app)/get-started/profile/use-cases": [44,[5,7],[6]],
		"/(app)/get-started/quickstart/connect-to-cloud": [45,[5,8],[6]],
		"/(app)/get-started/quickstart/create-namespace": [46,[5,8],[6]],
		"/(app)/get-started/quickstart/run-sample": [47,[5,8],[6]],
		"/(app)/get-started/quickstart/setup-local-environment": [48,[5,8],[6]],
		"/(no-auth)/jwt-missing-claims": [148,[29]],
		"/(no-auth)/login-error": [149,[29]],
		"/(auth)/login": [144,[28]],
		"/(auth)/login/callback": [145,[28]],
		"/(no-auth)/logout": [150,[29]],
		"/(app)/namespaces": [49,[2]],
		"/(app)/namespaces/create": [82,[2]],
		"/(app)/namespaces/[namespace]": [50,[2]],
		"/(app)/namespaces/[namespace]/(core)/batch-operations": [51,[2,9]],
		"/(app)/namespaces/[namespace]/(core)/batch-operations/[jobId]": [52,[2,9]],
		"/(app)/namespaces/[namespace]/custom-search-attributes": [77,[2]],
		"/(app)/namespaces/[namespace]/edit": [78,[2]],
		"/(app)/namespaces/[namespace]/export": [79,[2]],
		"/(app)/namespaces/[namespace]/failovers": [80,[2]],
		"/(app)/namespaces/[namespace]/(core)/schedules": [53,[2,9,10]],
		"/(app)/namespaces/[namespace]/(core)/schedules/create": [56,[2,9,10]],
		"/(app)/namespaces/[namespace]/(core)/schedules/[schedule]": [54,[2,9,10]],
		"/(app)/namespaces/[namespace]/(core)/schedules/[schedule]/edit": [55,[2,9,10]],
		"/(app)/namespaces/[namespace]/(core)/task-queues": [57,[2,9,11]],
		"/(app)/namespaces/[namespace]/(core)/task-queues/[queue]": [58,[2,9,11]],
		"/(app)/namespaces/[namespace]/usage": [81,[2]],
		"/(app)/namespaces/[namespace]/(core)/worker-deployments": [59,[2,9,12]],
		"/(app)/namespaces/[namespace]/(core)/worker-deployments/[deployment]": [60,[2,9,12,13]],
		"/(app)/namespaces/[namespace]/(core)/workflows": [61,[2,9,14]],
		"/(app)/namespaces/[namespace]/(core)/workflows/start-workflow": [76,[2,9,14]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]": [62,[2,9,14]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]": [63,[2,9,14,15]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/call-stack": [64,[2,9,14,15]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/history": [65,[2,9,14,15]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/history/compact": [66,[2,9,14,15]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/history/event-groups/[id]": [67,[2,9,14,15]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/history/events/[id]": [68,[2,9,14,15]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/history/feed": [69,[2,9,14,15]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/history/json": [70,[2,9,14,15]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/metadata": [71,[2,9,14,15]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/pending-activities": [72,[2,9,14,15]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/query": [73,[2,9,14,15]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/relationships": [74,[2,9,14,15]],
		"/(app)/namespaces/[namespace]/(core)/workflows/[workflow]/[run]/workers": [75,[2,9,14,15]],
		"/(app)/nexus": [83,[2,16]],
		"/(app)/nexus/create": [86,[2,16]],
		"/(app)/nexus/[id]": [84,[2,16,17]],
		"/(app)/nexus/[id]/edit": [85,[2,16,17]],
		"/(app)/profile": [87,[2,18]],
		"/(app)/profile/api-keys": [88,[2,18]],
		"/(app)/profile/api-keys/create": [90,[2]],
		"/(app)/profile/api-keys/[api_key]": [89,[2]],
		"/(app)/settings": [91,[2,19]],
		"/(app)/settings/api-keys": [92,[2,19]],
		"/(app)/settings/api-keys/create": [94,[2]],
		"/(app)/settings/api-keys/[api_key]": [93,[2,19]],
		"/(app)/settings/audit-logging": [95,[2,19]],
		"/(app)/settings/audit-logging/edit": [96,[2,19]],
		"/(app)/settings/identities": [97,[2,19]],
		"/(app)/settings/identities/service-accounts": [101,[2,19]],
		"/(app)/settings/identities/service-accounts/create": [102,[2,19]],
		"/(app)/settings/identities/users": [103,[2,19]],
		"/(app)/settings/identities/users/create": [104,[2,19]],
		"/(app)/settings/identities/[identity]": [98,[2,19,20]],
		"/(app)/settings/identities/[identity]/api-keys": [99,[2,19,20]],
		"/(app)/settings/identities/[identity]/edit": [100,[2]],
		"/(app)/settings/observability": [105,[2,19]],
		"/(app)/settings/service-accounts": [106,[2,19]],
		"/(app)/settings/service-accounts/[service_account]": [107,[2,19]],
		"/(app)/settings/service-accounts/[service_account]/edit": [108,[2,19]],
		"/(app)/settings/users": [109,[2,19]],
		"/(app)/settings/users/create": [113,[2,19]],
		"/(app)/settings/users/[user]": [110,[2,19]],
		"/(app)/settings/users/[user]/api-keys": [111,[2,19]],
		"/(app)/settings/users/[user]/edit": [112,[2,19]],
		"/(app)/support": [114,[2,21]],
		"/(app)/support/accounts": [115,[2,21,22]],
		"/(app)/support/accounts/aws-marketo": [121,[2,21,22]],
		"/(app)/support/accounts/create": [122,[2,21,22]],
		"/(app)/support/accounts/[accountid]": [116,[2,21,22]],
		"/(app)/support/accounts/[accountid]/configure-saml": [117,[2,21,22]],
		"/(app)/support/accounts/[accountid]/invite-user": [118,[2,21,22]],
		"/(app)/support/accounts/[accountid]/update-userlimit": [120,[2,21,22]],
		"/(app)/support/accounts/[accountid]/update": [119,[2,21,22]],
		"/(app)/support/cells": [123,[2,21,23]],
		"/(app)/support/cells/audit-cells": [125,[2,21,23]],
		"/(app)/support/cells/stats": [126,[2,21,23]],
		"/(app)/support/cells/[cellid]": [124,[2,21,23]],
		"/(app)/support/deploy": [127,[2,21,24]],
		"/(app)/support/deploy/deployments": [128,[2,21,24]],
		"/(app)/support/grant": [129,[2,21]],
		"/(app)/support/identities/api-keys": [130,[2,21,25]],
		"/(app)/support/identities/api-keys/[apikeyid]": [131,[2,21,25]],
		"/(app)/support/identities/service-accounts": [132,[2,21,25]],
		"/(app)/support/identities/service-accounts/[serviceaccountid]": [133,[2,21,25]],
		"/(app)/support/identities/users": [134,[2,21,25]],
		"/(app)/support/identities/users/[userid]": [135,[2,21,25]],
		"/(app)/support/namespaces": [136,[2,21,26]],
		"/(app)/support/namespaces/dynamic-conf-audit": [139,[2,21,26]],
		"/(app)/support/namespaces/[namespaceid]": [137,[2,21,26]],
		"/(app)/support/namespaces/[namespaceid]/update-config": [138,[2,21,26]],
		"/(app)/support/settings": [140,[2,21]],
		"/(app)/usage": [141,[2]],
		"/(app)/usage/[namespace]": [142,[2]],
		"/(no-auth)/use-sso": [151,[29]],
		"/(no-auth)/user-not-found": [152,[29]],
		"/(app)/welcome": [143,[2,27]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';